import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useContext } from 'react'
import { Auth } from '../../../allContext'
import { toMonthNameShort } from '../../../utils/date'
import { OrderPdfPrint } from '../MedicineOrder/OrderList/OrderPdf/OrderPdfPrint'
import classes from './AllOrderList.module.css'

export default function AllOrderList() {
    const [services, setServices] = useState([])
    const [medicines, setMedicines] = useState([])
    const [serviceId, setServiceId] = useState(0)
    const [pdfOpen, setPdfOpen] = useState(false)
    const { stateAuth } = useContext(Auth)
    const apiV1 = process.env.REACT_APP_API_V1
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/patients/services?skip=0&limit=15`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setServices(data)
                }
            } catch {
                setServices([])
            }
        }
        return fetchData()
    }, [token, apiV1])
    return (
        <div className={classes.wrapper}>
            <p>Health Plan List</p>
            <div className={classes.container}>
                {services[1] &&
                    services[1].map((service, index) =>
                        service.service_name === 'health_plan' ? (
                            <div className={classes.gridWrapper} key={index}>
                                <p>
                                    <FontAwesomeIcon icon={faFileAlt} className={classes.icon} />
                                    <span>{service.service_name.replace(/_/g, ' ')}</span>
                                </p>
                                <p>{service.order_value}TK</p>
                                <p>
                                    {`${service.order_placement.slice(8, 10)}-${toMonthNameShort(
                                        service.order_placement.slice(6, 7)
                                    )}-${service.order_placement.slice(0, 4)}`}
                                </p>
                                <p className={classes.status}>
                                    <span
                                        className={
                                            service.order_status === 'done'
                                                ? classes.active
                                                : service.order_status === 'processing'
                                                ? classes.progress
                                                : service.order_status === 'running'
                                                ? classes.running
                                                : service.order_status === 'emergency'
                                                ? classes.delete
                                                : service.order_status === 'cancel'
                                                ? classes.cancel
                                                : classes.waiting
                                        }>
                                        {service.order_status}
                                    </span>
                                </p>
                                <p className={classes.status}>
                                    <span
                                        className={
                                            service.payment_status === 'done' ? classes.active : classes.waiting
                                        }>
                                        Payment {service.payment_status}
                                    </span>
                                </p>
                                {service.service_name === 'medicine_order' ? (
                                    <div className={classes.btn}>
                                        <button
                                            onClick={() => {
                                                setServiceId(service?.id)
                                                setPdfOpen(index)
                                            }}>
                                            Download
                                        </button>
                                    </div>
                                ) : (
                                    <div className={classes.btn}>
                                        <button style={{ backgroundColor: 'var(--light-grey)', cursor: 'default' }}>
                                            Download
                                        </button>
                                    </div>
                                )}
                                {pdfOpen === index && (
                                    <OrderPdfPrint props={[service, medicines]} setPdfOpen={setPdfOpen} index={index} />
                                )}
                            </div>
                        ) : (
                            ''
                        )
                    )}
            </div>

            <p>Medicine Order List</p>
            <div className={classes.container}>
                {services[1] &&
                    services[1].map((service, index) =>
                        service.service_name === 'medicine_order' ? (
                            <div className={classes.gridWrapper} key={index}>
                                <p>
                                    <FontAwesomeIcon icon={faFileAlt} className={classes.icon} />
                                    <span>{service.service_name.replace(/_/g, ' ')}</span>
                                </p>
                                <p>{service.order_value}TK</p>
                                <p>
                                    {`${service.order_placement.slice(8, 10)}-${toMonthNameShort(
                                        service.order_placement.slice(6, 7)
                                    )}-${service.order_placement.slice(0, 4)}`}
                                </p>
                                <p className={classes.status}>
                                    <span
                                        className={
                                            service.order_status === 'done'
                                                ? classes.active
                                                : service.order_status === 'processing'
                                                ? classes.progress
                                                : service.order_status === 'running'
                                                ? classes.running
                                                : service.order_status === 'emergency'
                                                ? classes.delete
                                                : service.order_status === 'cancel'
                                                ? classes.cancel
                                                : classes.waiting
                                        }>
                                        {service.order_status}
                                    </span>
                                </p>
                                {service.service_name === 'medicine_order' ? (
                                    <div className={classes.btn}>
                                        <button
                                            onClick={() => {
                                                setServiceId(service?.id)
                                                setPdfOpen(index)
                                            }}>
                                            Download
                                        </button>
                                    </div>
                                ) : (
                                    <div className={classes.btn}>
                                        <button style={{ backgroundColor: 'var(--light-grey)', cursor: 'default' }}>
                                            Download
                                        </button>
                                    </div>
                                )}
                                {pdfOpen === index && (
                                    <OrderPdfPrint props={[service, medicines]} setPdfOpen={setPdfOpen} index={index} />
                                )}
                            </div>
                        ) : (
                            ''
                        )
                    )}
            </div>
        </div>
    )
}
