import { Landing } from '../components/index'

const LandingPage = () => {
    return (
        <div>
            <Landing />
        </div>
    )
}

export default LandingPage
