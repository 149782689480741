import { useReducer } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Auth, UserInfo, Gateway } from './allContext'
import { Slider } from './components'
import { LayoutProvider } from './context/layouContext'
import TestPage from './pages/TestPage'
import VideoPage from './pages/VideoPage'
import {
    LandingPage,
    HomePage,
    ProfilePage,
    FindDoctorsPage,
    RegisterPage,
    MedicalPage,
    LoginPage,
    IndicatorsPage,
    SettingsPage,
    OrderPage,
    SingleDoctorPage,
    ErrorPage,
    HealthPlanPage,
    PrescriptionPage,
    ForgotPasswordPage,
    ChangePasswordPage,
    OrderListPage,
    SuccessPage,
} from './pages/index'
import { authState, authReducer } from './reducer/authReducer'
import { gatewayState, gatewayReducer } from './reducer/gatewayReducer'
import { userState, userReducer } from './reducer/userReducer'
import ProtectedRoute from './routes/ProtectedRoute'

const App = () => {
    const [stateAuth, dispatchAuth] = useReducer(authReducer, authState)
    const [stateUser, dispatchUser] = useReducer(userReducer, userState)
    const [stateGateway, dispatchGateway] = useReducer(gatewayReducer, gatewayState)

    return (
        <div>
            <LayoutProvider>
                <Auth.Provider value={{ stateAuth, dispatchAuth }}>
                    <UserInfo.Provider value={{ stateUser, dispatchUser }}>
                        <Gateway.Provider value={{ stateGateway, dispatchGateway }}>
                            {/* Router Start */}
                            <Router>
                                <Switch>
                                    <Route path="/" exact={true} component={LandingPage} />
                                    <Route path="/slide" component={Slider} />

                                    <ProtectedRoute path="/home" component={HomePage} redirect="/" />
                                    <ProtectedRoute path="/profile" component={ProfilePage} redirect="/" />
                                    <ProtectedRoute path="/medicals" component={MedicalPage} redirect="/" />
                                    <ProtectedRoute path="/prescriptions" component={PrescriptionPage} redirect="/" />
                                    <ProtectedRoute path="/indicators" component={IndicatorsPage} redirect="/" />
                                    <ProtectedRoute path="/medicines" component={OrderPage} redirect="/" />
                                    <ProtectedRoute path="/doctors" component={FindDoctorsPage} redirect="/" />
                                    <ProtectedRoute path="/my-orders" component={OrderListPage} redirect="/" />
                                    <ProtectedRoute path="/doctor/:id" component={SingleDoctorPage} redirect="/" />
                                    <ProtectedRoute path="/settings" component={SettingsPage} redirect="/" />
                                    <ProtectedRoute path="/health-plans" component={HealthPlanPage} redirect="/" />
                                    <ProtectedRoute path="/videos" component={VideoPage} redirect="/" />
                                    <ProtectedRoute path="/payment-success" component={SuccessPage} redirect="/" />

                                    <Route path="/register" component={RegisterPage} />
                                    <Route path="/login" component={LoginPage} />
                                    <Route path="/test" component={TestPage} />
                                    <Route path="/forgot-password" component={ForgotPasswordPage} />
                                    <Route path="/reset-password" component={ChangePasswordPage} />
                                    <Route path="*" exact={true} component={ErrorPage} />
                                </Switch>
                            </Router>
                            {/* Router End */}
                        </Gateway.Provider>
                    </UserInfo.Provider>
                </Auth.Provider>
            </LayoutProvider>
        </div>
    )
}

export default App
