// import { Layout, OrderList } from '../components'
import { Layout, AllOrderList } from '../components'

const OrderListPage = () => {
    return (
        // <Layout>
        //     <OrderList />
        // </Layout>
        <Layout>
            <AllOrderList />
        </Layout>
    )
}

export default OrderListPage
