import { Howl } from 'howler'
import { useContext, useEffect, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Auth, UserInfo } from '../../allContext'
import Sound from '../../assets/sound/notification.mp3'

export default function Notifications() {
    // const { stateCount, dispatchCount } = useContext(Count)
    // const { setNotification } = useContext(Notification)

    const { stateUser } = useContext(UserInfo)
    const { stateAuth } = useContext(Auth)
    const id = stateUser?.info?.id || null
    const token = stateAuth.token || null

    const ws = useRef(null)
    const socketApi = process.env.REACT_APP_SOCKET_URL
    const reconnectInterval = 5000 // 5 seconds

    const createWebSocket = (token, id) => {
        ws.current = new WebSocket(`${socketApi}/ws/notifications/patients/${token}/${id}`)

        // reconnect after a delay
        ws.current.onclose = (event) => {
            if (event.code !== 1000) {
                setTimeout(() => createWebSocket(token, id), reconnectInterval)
            }
        }
    }

    useEffect(() => {
        if (token && id) {
            createWebSocket(token, id)
            ws.current = new WebSocket(`${socketApi}/ws/notifications/patients/${token}/${id}`)
            ws.current.onmessage = onMessage

            // eslint-disable-next-line no-unused-vars
            const sound = new Howl({
                src: [Sound],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, id])

    const onMessage = (ev) => {
        const sound = new Howl({
            src: [Sound],
        })
        sound.play()

        const message = ev.data
        toast.info(message, { autoClose: 5000 })
        // setNotification(true)
        // dispatchCount({ type: 'bell', payload: stateCount.bell + 1 })
        // dispatchCount({ type: 'total', payload: stateCount.total + 1 })
    }

    return <ToastContainer />
}
