import { faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParticipant } from '@videosdk.live/react-sdk'
import { useEffect, useMemo, useRef } from 'react'
import ReactPlayer from 'react-player'
import classes from './ParticipantView.module.css'

export default function ParticipantView(props) {
    const micRef = useRef(null)
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(props.participantId)
    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream()
            mediaStream.addTrack(webcamStream.track)
            return mediaStream
        }
    }, [webcamStream, webcamOn])

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream()
                mediaStream.addTrack(micStream.track)

                micRef.current.srcObject = mediaStream
                micRef.current.play().catch((error) => console.error('videoElem.current.play() failed', error))
            } else {
                micRef.current.srcObject = null
            }
        }
    }, [micStream, micOn])

    return (
        <div className={classes.view}>
            <p>
                {displayName} | <FontAwesomeIcon icon={faVideo} /> : {webcamOn ? 'ON' : 'OFF'} |{' '}
                <FontAwesomeIcon icon={faMicrophone} /> : {micOn ? 'ON' : 'OFF'}
            </p>
            <audio ref={micRef} autoPlay playsInline muted={isLocal} />
            {webcamOn && (
                <div className={classes.box}>
                    <ReactPlayer
                        //
                        playsinline // very very imp prop
                        pip={false}
                        light={false}
                        controls={false}
                        muted={true}
                        playing={true}
                        //
                        url={videoStream}
                        //
                        height="100%"
                        width="100%"
                        style={{ display: 'flex' }}
                        className={classes.player}
                        onError={(err) => {
                            console.log(err, 'participant video error')
                        }}
                    />
                </div>
            )}
        </div>
    )
}
