import React from 'react'
import { Video } from '../components'

export default function VideoPage() {
    return (
        <div>
            <Video />
        </div>
    )
}
