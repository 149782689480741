import { MobileNav, Navbar, SidebarV2 } from '../Nav'
import Notifications from '../Notifications/Notifications'
import classes from './Layout.module.css'

export default function Layout({ children, side }) {
    return (
        <>
            <Notifications />
            <div className={classes.layout}>
                <div>
                    <Navbar />
                    <MobileNav />
                </div>
                <div className={classes.container}>
                    <div>
                        <SidebarV2 />
                    </div>
                    <div>{children}</div>
                </div>
            </div>
        </>
    )
}
