import React from 'react'
import { HealthPlan, Layout } from '../components'

export default function HealthPlanPage() {
    return (
        <Layout>
            <HealthPlan />
        </Layout>
    )
}
