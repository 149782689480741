import { faMicrophone, faMicrophoneAltSlash, faPhone, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMeeting } from '@videosdk.live/react-sdk'
import { useState } from 'react'
import classes from './Controls.module.css'

export default function Controls() {
    const { leave, toggleMic, toggleWebcam } = useMeeting()
    const [video, setVideo] = useState(true)
    const [audio, setAudio] = useState(true)

    return (
        <div className={classes.control}>
            <div>
                <p
                    className={audio ? '' : classes.audio}
                    onClick={() => {
                        toggleMic()
                        setAudio((prev) => !prev)
                    }}>
                    <FontAwesomeIcon icon={audio ? faMicrophone : faMicrophoneAltSlash} />
                </p>
                <p
                    className={video ? '' : classes.video}
                    onClick={() => {
                        toggleWebcam()
                        setVideo((prev) => !prev)
                    }}>
                    <FontAwesomeIcon icon={video ? faVideo : faVideoSlash} />
                </p>
                <p onClick={() => leave()}>
                    <FontAwesomeIcon icon={faPhone} />
                </p>

                {/* <button onClick={() => toggleMic()}>toggleMic</button> */}
                {/* <button onClick={() => toggleWebcam()}>toggleWebcam</button> */}
                {/* <button onClick={() => leave()}>Leave</button> */}
            </div>
        </div>
    )
}
