import { PatDefStorage, PatSetStorage, PatGetStorage } from '../utils/PatSessionStorage'

export const gatewayState = PatDefStorage('gateway', { trx: '' })

export const gatewayReducer = (state, action) => {
    switch (action.type) {
        case 'get':
            PatSetStorage('gateway', { trx: state.info })
            return JSON.parse(PatGetStorage('gateway'))
        case 'set':
            PatSetStorage('gateway', { trx: action.payload })
            return JSON.parse(PatGetStorage('gateway'))
        case 'remove':
            PatSetStorage('gateway', { trx: '' })
            return JSON.parse(PatGetStorage('gateway'))
        default:
            return state
    }
}
