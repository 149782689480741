import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Auth, Gateway } from '../../../allContext'
import Successful from '../../../assets/payment/success.png'
import classes from './Success.module.css'

export default function Success() {
    const apiV1 = process.env.REACT_APP_API_V1
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const { stateGateway } = useContext(Gateway)
    const trxId = stateGateway.trx

    const [pay, setPay] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/payments/details/${trxId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPay(data)
                }
            } catch {
                console.log('error')
            }
        }
        return fetchData()
    }, [token, apiV1, trxId])

    return (
        <div className={classes.success}>
            <div>
                <img src={Successful} alt="" />
            </div>

            <div className={classes.details}>
                <h1>Payment Successful</h1>
                <p>
                    Transaction ID: <span>{pay.mer_txnid}</span>
                </p>
                <p>
                    Amount Paid: <span>৳{pay.amount_bdt}</span>
                </p>
                <Link to="/my-orders">Go to Orders</Link>
            </div>
        </div>
    )
}
