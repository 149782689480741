import { Register } from '../components/index'

const RegisterPage = () => {
    return (
        <div>
            <Register />
        </div>
    )
}

export default RegisterPage
