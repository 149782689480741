import { Login } from '../components/index'

const LoginPage = () => {
    return (
        <div>
            <Login />
        </div>
    )
}

export default LoginPage
