import { Layout, Settings } from '../components'

const SettingsPage = () => {
    return (
        <Layout>
            <Settings />
        </Layout>
    )
}

export default SettingsPage
