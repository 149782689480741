import React from 'react'
import { ForgotPassword } from '../components'

export default function ForgotPasswordPage() {
    return (
        <div>
            <ForgotPassword />
        </div>
    )
}
