import React from 'react'
import Load from '../../assets/loading/loading.gif'
import classes from './Loading.module.css'

export default function Loading() {
    return (
        <div className={classes.loading}>
            <img src={Load} alt="Loading GIF" />
        </div>
    )
}
