import { useMeeting } from '@videosdk.live/react-sdk'
import { useState } from 'react'
import Controls from './Controls/Controls'
import classes from './MeetingView.module.css'
import ParticipantView from './ParticipantView/ParticipantView'

export default function MeetingView(props) {
    const [joined, setJoined] = useState(null)
    //Get the method which will be used to join the meeting.
    //We will also get the participants list to display all participants
    const { join, participants } = useMeeting({
        //callback for when meeting is joined successfully
        onMeetingJoined: () => {
            setJoined('JOINED')
        },
        //callback for when meeting is left
        onMeetingLeft: () => {
            props.onMeetingLeave()
        },
    })
    const joinMeeting = () => {
        setJoined('JOINING')
        join()
    }

    return (
        <div className={classes.meeting}>
            <div className={classes.box}>
                <h3>Meeting ID: {props.meetingId}</h3>
                {joined && joined === 'JOINED' ? (
                    <>
                        <Controls />
                        <div className={classes.view}>
                            {[...participants.keys()].map((participantId) => (
                                <ParticipantView participantId={participantId} key={participantId} />
                            ))}
                        </div>
                    </>
                ) : joined && joined === 'JOINING' ? (
                    <div className={classes.flex}>
                        <p>Starting The Video Meeting...</p>
                    </div>
                ) : (
                    <div className={classes.flex}>
                        <button onClick={joinMeeting}>Start Video Calling</button>
                    </div>
                )}
            </div>
        </div>
    )
}
