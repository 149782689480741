//Auth token we will use to generate a meeting and connect to it
export const authToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJkNTE5ODZkZi1kYTMzLTQ4MDAtYWEyNC00ZmFlMjMyZGM5ODMiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY4NTMzNTk1MCwiZXhwIjoxNjg1OTQwNzUwfQ.8xkapOpFb62n65zcSIdM5rr-t4FSviP1EsBPaegEshU'
// API call to create meeting
export const createMeeting = async ({ token }) => {
    const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
        method: 'POST',
        headers: {
            authorization: `${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    })
    //Destructuring the roomId from the response
    const { roomId } = await res.json()
    return roomId
}
