import { useState } from 'react'
import healthPlan from '../../../assets/img/offer/healthPlan.jpg'
import Loading from '../../Loading/Loading'
import PlanForm from './PlanForm/PlanForm'
import classes from './PlanList.module.css'

export default function PlanList({ plans }) {
    const [openForm, setOpenForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className={classes.wrapper}>
                    <div className={classes.container}>
                        {plans &&
                            plans.map((plan, index) =>
                                plan.plan_type === 'health_plan' ? (
                                    <div className={classes.containerFlex}>
                                        <div className={classes.flex}>
                                            <div className={classes.imgDiv}>
                                                <img src={healthPlan} alt="" />{' '}
                                            </div>

                                            <div>
                                                <p className={classes.plan}>
                                                    ৳{plan.fee}
                                                    <span> / for {plan.total_patients} Adult</span>
                                                    <span>
                                                        ({' '}
                                                        {Math.floor(plan.days / 30) === 0
                                                            ? 1
                                                            : Math.floor(plan.days / 30)}{' '}
                                                        {plan.days / 30 > 1
                                                            ? 'Months'
                                                            : Math.floor(plan.days / 30) === 0
                                                            ? 'Day'
                                                            : 'Month'}{' '}
                                                        Plan )
                                                    </span>
                                                </p>
                                                {/* <p>
                                            {Math.floor(plan.days / 30) === 0 ? 1 : Math.floor(plan.days / 30)}{' '}
                                            {plan.days / 30 > 1
                                                ? 'Months'
                                                : Math.floor(plan.days / 30) === 0
                                                ? 'Day'
                                                : 'Month'}{' '}
                                            Plan
                                        </p> */}
                                            </div>
                                        </div>
                                        <div>
                                            <h6>{plan.name}</h6>
                                            <p>{plan.details}</p>
                                        </div>

                                        <div className={classes.btn}>
                                            <button onClick={() => setOpenForm(index)}>Subscribe</button>
                                        </div>

                                        {openForm === index && (
                                            <PlanForm
                                                setOpenForm={setOpenForm}
                                                setIsLoading={setIsLoading}
                                                plan={plan}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    ''
                                )
                            )}
                    </div>
                </div>
            )}
        </>
    )
}
