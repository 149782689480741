import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Doc from '../../../assets/img/doc/doc-df.jpg'
import classes from './DoctorList.module.css'
import Popup from './Popup/Popup.jsx'

const DoctorList = ({ doctors, apiV1 }) => {
    const [isOpen, setIsOpen] = useState(false)
    // patient portal
    return (
        <div className={classes.DoctorList}>
            {doctors[1] &&
                doctors[1].map((doctor, i) => (
                    <div className={classes.doctor} key={i}>
                        <div>
                            <Link to={`/doctor/${doctor?.User?.id + 1000}`}>
                                <div
                                    className={classes.doctorPic}
                                    style={{
                                        backgroundImage:
                                            doctor?.Doctor?.images.length !== 0
                                                ? `url(${apiV1}/images/profile/${doctor?.Doctor?.images[0]?.image_string})`
                                                : `url(${Doc})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        border: '4px solid var(--light)',
                                    }}></div>
                                <span className={classes.bmdc}>BMDC: {doctor?.Doctor?.bmdc}</span>
                            </Link>
                        </div>
                        <div className={classes.nameContainer}>
                            <p className={classes.name}>
                                <Link to={`/doctor/${doctor?.User?.id + 1000}`}>
                                    {`${doctor?.Doctor?.dr_title || ''} ${doctor?.User?.name || ''}`}
                                </Link>
                            </p>
                            <p className={classes.qualification}>{doctor?.DoctorQualification?.qualification}</p>
                            <p className={classes.speciality}>{doctor?.DoctorSpeciality?.speciality}</p>
                            <p className={classes.designation}>{doctor?.Doctor?.workplace[0]?.position}</p>
                            <p className={classes.currentWorkPlace}>{doctor?.Doctor?.workplace[0]?.institute}</p>
                            {/* <p className={classes.currentWorkPlace}>Dhaka Medical College & Hospital Shahbag, Dhaka</p> */}
                        </div>
                        <div>
                            <div className={classes.fees}>
                                <p>
                                    ৳
                                    {doctor?.Doctor?.online_fees !== null
                                        ? doctor?.Doctor?.online_fees > 499
                                            ? doctor?.Doctor?.online_fees + 100
                                            : doctor?.Doctor?.online_fees + doctor?.Doctor?.online_fees * (20 / 100)
                                        : ''}
                                    <span> (Inc. VAT)</span>
                                </p>
                                <span>Consultation Fee</span>
                            </div>
                            <p className={classes.experience}>
                                {doctor?.Doctor?.exp_year !== null
                                    ? `${doctor?.Doctor?.exp_year}+ Years of Experience`
                                    : ''}
                            </p>
                            {/* <span>
                                Ratings(1) 5.0
                                <FontAwesomeIcon icon={faStar} style={{ color: 'orange', fontSize: '12px' }} />
                            </span> */}
                        </div>
                        <div>
                            <p onClick={() => setIsOpen(i)}>
                                {/* BDT{' '}
                                {doctor?.Doctor?.online_fees !== null
                                    ? doctor?.Doctor?.online_fees > 499
                                        ? doctor?.Doctor?.online_fees + 100
                                        : doctor?.Doctor?.online_fees + doctor?.Doctor?.online_fees * (20 / 100)
                                    : ''} */}
                                Book Appoinment
                            </p>
                            <button>
                                {/* <p onClick={() => setIsOpen(i)}>Book Appoinment</p> */}

                                <Link to={`/doctor/${doctor?.User?.id + 1000}`}> View Profile </Link>
                            </button>
                        </div>
                        {isOpen === i && <Popup setIsOpen={setIsOpen} doctor={doctor} index={i} />}
                    </div>
                ))}
        </div>
    )
}

export default DoctorList
