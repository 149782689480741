import React from 'react'
import { ChangePassword } from '../components'

export default function ChangePasswordPage() {
    return (
        <div>
            <ChangePassword />
        </div>
    )
}
