import { MeetingProvider } from '@videosdk.live/react-sdk'
import { useContext, useState } from 'react'
import { UserInfo } from '../../allContext'
import { authToken, createMeeting } from './API'
import JoinScreen from './JoinScreen/JoinScreen'
import MeetingView from './MeetingView/MeetingView'
import classes from './Video.module.css'

export default function Video() {
    const [meetingId, setMeetingId] = useState(null)

    const { stateUser } = useContext(UserInfo)
    const userDetail = stateUser.info

    //Getting the meeting id by calling the api we just wrote
    const getMeetingAndToken = async (id) => {
        const meetingId = id == null ? await createMeeting({ token: authToken }) : id
        setMeetingId(meetingId)
    }

    //This will set Meeting Id to null when meeting is left or ended
    const onMeetingLeave = () => {
        setMeetingId(null)
    }

    return authToken && meetingId ? (
        <MeetingProvider
            config={{
                // meetingId: 'w988-gtij-zeuk',
                meetingId,
                micEnabled: true,
                webcamEnabled: true,
                name: userDetail?.name,
            }}
            token={authToken}>
            <div className={classes.video}>
                <MeetingView meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
            </div>
        </MeetingProvider>
    ) : (
        <div className={classes.video}>
            <JoinScreen getMeetingAndToken={getMeetingAndToken} />
        </div>
    )
}
