import { useContext, useEffect, useState } from 'react'
import { Auth, UserInfo } from '../../../allContext'
import classes from './ProfileCard.module.css'

const ProfileCard = ({ userDetail }) => {
    const { stateUser } = useContext(UserInfo)
    const { stateAuth } = useContext(Auth)

    const [pic, setPic] = useState({})
    const [weight, setWeight] = useState()
    const [userDetails, setUserDetails] = useState()

    const apiV1 = process.env.REACT_APP_API_V1
    const token = stateAuth.token

    // useEffect(() => {
    //     let imgInfoFunc = async () => {
    //         let imgInfoFetch = await fetch(`${apiV1}/profile-pic`, {
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             method: 'GET',
    //         })
    //         let infoJson = await imgInfoFetch.json()

    //         if (imgInfoFetch.ok) {
    //             setPic(infoJson.image_string)
    //         }
    //     }
    //     try {
    //         imgInfoFunc()
    //     } catch (e) {}
    // }, [apiV1, token])

    // indecator information
    useEffect(() => {
        let indecatorFunc = async () => {
            let indecatorFetch = await fetch(`${apiV1}/patient/indicators/last/weight`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
            let infoJson = await indecatorFetch.json()

            if (indecatorFetch.ok) {
                setWeight(infoJson.slot_flt4)
            }
        }
        try {
            indecatorFunc()
        } catch (e) {
            setWeight([])
        }
    }, [apiV1, token])

    useEffect(() => {
        let userInfoFunc = async () => {
            let userInfoFetch = await fetch(`${apiV1}/user/details/`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
            let infoJson = await userInfoFetch.json()

            if (userInfoFetch.ok) {
                // setUserDetails(infoJson)
                setUserDetails(infoJson.blood_group)
            }
        }
        try {
            userInfoFunc()
        } catch (e) {
            setUserDetails([])
        }
    }, [apiV1, token])

    // for user detail

    return (
        <div className={classes.profileCard}>
            <div>
                {/* <span>Welcome, </span>
                <span className={classes.name}>{stateUser?.info?.name}</span> */}
            </div>
            <div>
                <div>
                    <span>{userDetails}</span>
                    <p>Blood</p>
                </div>
                <div>|</div>
                <div>
                    {/* <span>22</span> */}
                    <p>BMI</p>
                </div>
                <div>|</div>
                <div>
                    <span> {weight}</span>
                    <p>Weight</p>
                </div>
            </div>
        </div>
    )
}

export default ProfileCard
