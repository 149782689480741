import React, { useState } from 'react'
import classes from './JoinScreen.module.css'

export default function JoinScreen({ getMeetingAndToken }) {
    const [meetingId, setMeetingId] = useState(null)

    const onClick = async () => {
        await getMeetingAndToken(meetingId)
    }

    return (
        <div className={classes.join}>
            <div className={classes.box}>
                {/* <input
                    type="text"
                    placeholder="Enter Meeting Id"
                    onChange={(e) => {
                        setMeetingId(e.target.value)
                    }}
                /> */}
                {/* <button onClick={onClick}>Join</button>
                {' or '} */}
                <span onLoad={onClick()}></span>
            </div>
        </div>
    )
}
